<template>
    <v-card>
        <v-card-text>
            <issue-attr-selector
                :outlined="true"
                :label="$t(`projects.attributes.fieldsTracker`)"
                v-model="fields.tracker"
            />

            <issue-attr-selector
                :outlined="true"
                :label="$t(`projects.attributes.fieldsCreation`)"
                v-model="fields.creation"
            />

            <v-btn color="success" class="mr-4" @click="submitForm">
                {{ $t('projects.actions.update') }}
            </v-btn>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import issueAttrSelector from '@/components/selectors/multiple/issueAttributes';
import Clone from 'lodash/cloneDeep';

export default {
    name: 'project-setting-fields',
    data: () => ({
        fields: {},
    }),
    computed: {
        ...mapGetters(['project']),
    },
    created() {
        this.updateForm(this.project);
    },
    watch: {
        project: function (value) {
            this.updateForm(value);
        },
        field: function (value) {
            this.updateForm(value);
        },
    },
    components: { issueAttrSelector },
    methods: {
        ...mapActions(['updateProject']),
        updateForm(p) {
            if (p) {
                this.fields = Clone(p.fields);
            }
        },
        submitForm() {
            const projectToUpdate = {
                id: this.project.id,
                fields: this.fields,
            };
            this.updateProject(projectToUpdate);
        },
    },
};
</script>

<style></style>
