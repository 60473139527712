<template>
    <v-card>
        <v-card-text>
            <v-data-table :headers="headers" :items="project.members" :items-per-page="20" sort-by="user">
                <template v-slot:top>
                    <v-toolbar flat color="transparent">
                        <v-spacer></v-spacer>
                        <v-dialog v-model="dialog" max-width="800px" persistent>
                            <template v-slot:activator="{ on }">
                                <v-btn rounded color="primary" dark v-on="on">
                                    {{ $t('buttons.createMember') }}
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-title>
                                    <span class="headline blue--text text--darken-1">{{ formTitle }}</span>
                                </v-card-title>

                                <v-card-text>
                                    <selector-user v-model="editMember.user" :label="$t('projects.members.user')" />

                                    <selector-roles v-model="editMember.roles" :label="$t('projects.members.roles')" />
                                </v-card-text>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="red darken-1" text @click="cancelEdit">
                                        {{ $t('buttons.cancel') }}
                                    </v-btn>
                                    <v-btn color="blue darken-1" text @click="save">
                                        {{ $t('buttons.save') }}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>

                <template v-slot:[`item.roles`]="{ item }">
                    <labelRoles :value="item.roles" />
                </template>

                <template v-slot:[`item.user`]="{ item }">
                    <label-user :value="item.user" :avatar="true" />
                </template>

                <template v-slot:[`item.joinedAt`]="{ item }">
                    {{ formatDate(item.joinedAt) }}
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
                    <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { formatDateMixin } from '@/mixins/dates';
import labelUser from '@/components/labels/user';
import labelRoles from '@/components/labels/roles';
import selectorUser from '@/components/selectors/simple/user';
import selectorRoles from '@/components/selectors/multiple/roles';

export default {
    name: 'project-setting-members',
    data: () => ({
        dialog: false,
        editMember: {
            user: undefined,
            roles: [],
        },
    }),
    created() {},
    mixins: [formatDateMixin],
    components: {
        labelUser,
        labelRoles,
        selectorUser,
        selectorRoles,
    },
    watch: {},
    computed: {
        ...mapGetters(['project']),
        headers() {
            return [
                {
                    text: this.$t('projects.members.user'),
                    sortable: true,
                    value: 'user',
                },
                {
                    text: this.$t('projects.members.joinedAt'),
                    sortable: true,
                    value: 'joinedAt',
                },
                {
                    text: this.$t('projects.members.roles'),
                    sortable: false,
                    value: 'roles',
                },
                {
                    text: this.$t('projects.categories.actions'),
                    sortable: false,
                    value: 'actions',
                    width: 50,
                },
            ];
        },
        formTitle() {
            return this.editMember.user ? this.$t('title.editMember') : this.$t('title.newMember');
        },
    },
    methods: {
        ...mapActions(['updateProjectMember', 'deleteProjectMember']),
        cancelEdit() {
            this.dialog = false;
            this.editMember = {
                user: undefined,
                roles: [],
            };
        },
        editItem(item) {
            this.dialog = true;
            this.editMember = Object.assign({}, item);
        },
        deleteItem(item) {
            const memberToUpdate = {
                id: this.project.id,
                user: item.user,
                roles: item.roles,
            };

            this.deleteProjectMember(memberToUpdate);
        },
        save() {
            const memberToUpdate = {
                id: this.project.id,
                user: this.editMember.user,
                roles: this.editMember.roles,
            };

            this.updateProjectMember(memberToUpdate);
            this.cancelEdit();
        },
    },
};
</script>

<style></style>
