<template>
    <v-card>
        <v-dialog v-model="dialogDelete" max-width="800px" persistent>
            <v-card>
                <v-card-title>
                    <span class="headline red--text text--darken-1" v-text="$t('title.deleteCategory')"></span>
                </v-card-title>
                <v-card-text>
                    <span class="subtitle-2 text--darken-1" v-text="$t('title.deleteCategoryReplace')"></span>
                </v-card-text>

                <v-card-text>
                    <category-selector v-model="deletingCategory.replaceName" :excludes="[deletingCategory.name]" />
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey darken-1" text @click="cancelDelete">
                        {{ $t('buttons.cancel') }}
                    </v-btn>
                    <v-btn color="red darken-1" text @click="removeCategory">
                        {{ $t('buttons.delete') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-card-text>
            <v-data-table :headers="headers" :items="project.categories" :items-per-page="20" sort-by="name">
                <template v-slot:top>
                    <v-toolbar flat color="transparent">
                        <v-spacer></v-spacer>
                        <v-dialog v-model="dialog" max-width="800px" persistent>
                            <template v-slot:activator="{ on }">
                                <v-btn rounded color="primary" dark v-on="on">
                                    {{ $t('buttons.createCategory') }}
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-title>
                                    <span class="headline blue--text text--darken-1">{{ formTitle }}</span>
                                </v-card-title>

                                <v-card-text>
                                    <v-text-field
                                        v-model="editCategory.name"
                                        :counter="20"
                                        :label="$t('projects.categories.name')"
                                        required
                                        color="red"
                                    ></v-text-field>

                                    <selector-user
                                        v-model="editCategory.user"
                                        :label="$t('projects.categories.user')"
                                    />
                                    <selector-modules
                                        v-model="editCategory.modules"
                                        :label="$t('projects.categories.modules')"
                                    />
                                </v-card-text>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="red darken-1" text @click="cancelEdit">
                                        {{ $t('buttons.cancel') }}
                                    </v-btn>
                                    <v-btn color="blue darken-1" text @click="save">
                                        {{ $t('buttons.save') }}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>

                <template v-slot:[`item.modules`]="{ item }">
                    <label-modules :value="item.modules" />
                </template>
                <template v-slot:[`item.user`]="{ item }">
                    <label-user :value="item.user" :avatar="false" />
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
                    <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import labelModules from '@/components/labels/modules';
import labelUser from '@/components/labels/user';
import selectorModules from '@/components/selectors/multiple/modules';
import selectorUser from '@/components/selectors/simple/user';
import categorySelector from '@/components/selectors/simple/category';

export default {
    name: 'project-setting-categories',
    data: () => ({
        dialog: false,
        editCategory: {
            name: undefined,
            user: undefined,
            modules: [],
        },
        dialogDelete: false,
        deletingCategory: {},
    }),
    created() {},
    components: {
        labelModules,
        labelUser,
        selectorModules,
        selectorUser,
        categorySelector,
    },
    watch: {},
    computed: {
        ...mapGetters(['project']),
        headers() {
            return [
                {
                    text: this.$t('projects.categories.name'),
                    sortable: true,
                    value: 'name',
                },
                {
                    text: this.$t('projects.categories.user'),
                    sortable: true,
                    value: 'user',
                },
                {
                    text: this.$t('projects.categories.modules'),
                    sortable: false,
                    value: 'modules',
                },
                {
                    text: this.$t('projects.categories.actions'),
                    sortable: false,
                    value: 'actions',
                    width: 50,
                },
            ];
        },
        formTitle() {
            return this.editCategory.name ? this.$t('title.editCategory') : this.$t('title.newCategory');
        },
    },
    methods: {
        ...mapActions(['updateCategory', 'deleteCategory']),
        cancelEdit() {
            this.dialog = false;
            this.editCategory = {
                name: undefined,
                user: undefined,
                modules: [],
            };
        },
        cancelDelete() {
            this.dialogDelete = false;
            this.deletingCategory = {};
        },
        editItem(item) {
            this.dialog = true;
            this.editCategory.name = item.name;
            this.editCategory.user = item.user;
            this.editCategory.modules = item.modules;
        },
        deleteItem(item) {
            this.dialogDelete = true;
            this.deletingCategory = {
                name: item.name,
                replaceName: '',
            };
        },
        removeCategory() {
            const categoryToUpdate = {
                id: this.project.id,
                name: this.deletingCategory.name,
                replaceName: this.deletingCategory.replaceName,
            };

            this.deleteCategory(categoryToUpdate);
            this.cancelDelete();
        },
        save() {
            const categoryToUpdate = {
                id: this.project.id,
                name: this.editCategory.name,
                user: this.editCategory.user,
                modules: this.editCategory.modules,
            };

            this.updateCategory(categoryToUpdate);
            this.cancelEdit();
        },
    },
};
</script>

<style></style>
