<template>
    <v-card>
        <v-card-text>
            <v-switch v-model="jenkins.enable" :label="$t('projects.jenkins.enable')"></v-switch>
            <v-text-field
                v-model="jenkins.token"
                :label="$t('projects.jenkins.token')"
                required
                outlined
                color="red"
            ></v-text-field>

            <v-btn color="success" class="mr-4" @click="submitForm">
                {{ $t('projects.actions.update') }}
            </v-btn>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Clone from 'lodash/cloneDeep';

export default {
    name: 'project-setting-jenkins',
    data: () => ({
        jenkins: {},
    }),
    created() {
        this.updateForm(this.project);
    },
    watch: {
        project: function (value) {
            this.updateForm(value);
        },
    },
    computed: {
        ...mapGetters(['project']),
    },
    methods: {
        ...mapActions(['updateIntegration']),
        updateForm(p) {
            if (p) {
                this.jenkins = Clone(p.integrations.jenkins);
            }
        },
        submitForm() {
            const projectToUpdate = {
                id: this.project.id,
                tool: 'jenkins',
                enable: this.jenkins.enable,
                token: this.jenkins.token,
            };
            this.updateIntegration(projectToUpdate);
        },
    },
};
</script>

<style></style>
