<template>
    <v-row>
        <v-col cols="2">
            <settingDrawer />
        </v-col>
        <v-col cols="10">
            <project-setting-general v-if="activeMenu === '#definition'" :key="activeMenu" />
            <project-setting-trackers v-if="activeMenu === '#trackers'" :key="activeMenu" />
            <project-setting-status v-if="activeMenu === '#status'" :key="activeMenu" />
            <project-setting-priorities v-if="activeMenu === '#priorities'" :key="activeMenu" />
            <project-setting-severities v-if="activeMenu === '#severities'" :key="activeMenu" />
            <project-setting-versions v-if="activeMenu === '#versions'" :key="activeMenu" />
            <project-setting-modules v-if="activeMenu === '#modules'" :key="activeMenu" />
            <project-setting-categories v-if="activeMenu === '#categories'" :key="activeMenu" />
            <project-setting-members v-if="activeMenu === '#members'" :key="activeMenu" />
            <project-setting-views v-if="activeMenu === '#views'" :key="activeMenu" />
            <project-setting-fields v-if="activeMenu === '#fields'" :key="activeMenu" />
            <project-setting-gitlab v-if="activeMenu === '#gitlab'" :key="activeMenu" />
            <project-setting-team v-if="activeMenu === '#team'" :key="activeMenu" />
            <project-setting-jenkins v-if="activeMenu === '#jenkins'" :key="activeMenu" />
        </v-col>
    </v-row>
</template>

<script>
import settingDrawer from '@/components/drawers/drawer-project-settings';
import projectSettingGeneral from './setting/general';
import projectSettingTrackers from './setting/trackers';
import projectSettingStatus from './setting/status';
import projectSettingPriorities from './setting/priorities';
import projectSettingSeverities from './setting/severities';
import projectSettingVersions from './setting/versions';
import projectSettingModules from './setting/modules';
import projectSettingCategories from './setting/categories';
import projectSettingMembers from './setting/members';
import projectSettingViews from './setting/views';
import projectSettingFields from './setting/fields';
import projectSettingGitlab from './setting/gitlab';
import projectSettingTeam from './setting/team';
import projectSettingJenkins from './setting/jenkins';

export default {
    name: 'project-settings',
    data: () => ({}),
    components: {
        settingDrawer,
        projectSettingGeneral,
        projectSettingTrackers,
        projectSettingStatus,
        projectSettingPriorities,
        projectSettingSeverities,
        projectSettingVersions,
        projectSettingModules,
        projectSettingCategories,
        projectSettingMembers,
        projectSettingViews,
        projectSettingFields,
        projectSettingGitlab,
        projectSettingTeam,
        projectSettingJenkins,
    },
    created() {
        if (!this.$route.hash) {
            this.$router.push({ name: this.$route.name, hash: '#definition' });
        }
    },
    computed: {
        activeMenu() {
            return this.$route.hash;
        },
    },
    methods: {},
};
</script>
