<template>
    <v-card>
        <v-card-text>
            <v-switch v-model="team.enable" :label="$t('projects.team.enable')"></v-switch>
            <v-text-field v-model="team.newIssue" :label="$t('projects.team.newIssue')" outlined></v-text-field>

            <v-text-field v-model="team.updateIssue" :label="$t('projects.team.updateIssue')" outlined></v-text-field>
            <v-text-field v-model="team.comment" :label="$t('projects.team.comment')" outlined></v-text-field>
            <v-text-field v-model="team.releaseVersion" :label="$t('projects.team.releaseVersion')" outlined>
            </v-text-field>
            <v-text-field v-model="team.pushGit" :label="$t('projects.team.pushGit')" outlined></v-text-field>
            <v-text-field v-model="team.pushJenkins" :label="$t('projects.team.pushJenkins')" outlined> </v-text-field>
            <v-btn color="success" class="mr-4" @click="submitForm">
                {{ $t('projects.actions.update') }}
            </v-btn>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Clone from 'lodash/cloneDeep';

export default {
    name: 'project-setting-team',
    data: () => ({
        team: {},
    }),
    created() {
        this.updateForm(this.project);
    },
    watch: {
        project: function (value) {
            this.updateForm(value);
        },
    },
    computed: {
        ...mapGetters(['project']),
    },
    methods: {
        ...mapActions(['updateIntegration']),
        updateForm(p) {
            if (p) {
                this.team = Clone(p.integrations.team);
                this.team.newIssue = this.team.newIssue.join(';');
                this.team.updateIssue = this.team.updateIssue.join(';');
                this.team.comment = this.team.comment.join(';');
                this.team.releaseVersion = this.team.releaseVersion.join(';');
                this.team.pushGit = this.team.pushGit.join(';');
                this.team.pushJenkins = this.team.pushJenkins.join(';');
            }
        },
        submitForm() {
            const projectToUpdate = {
                id: this.project.id,
                tool: 'team',
                enable: this.team.enable,
                newIssue: [...new Set(this.team.newIssue.split(';'))].filter(Boolean),
                updateIssue: [...new Set(this.team.updateIssue.split(';'))].filter(Boolean),
                comment: [...new Set(this.team.comment.split(';'))].filter(Boolean),
                releaseVersion: [...new Set(this.team.releaseVersion.split(';'))].filter(Boolean),
                pushGit: [...new Set(this.team.pushGit.split(';'))].filter(Boolean),
                pushJenkins: [...new Set(this.team.pushJenkins.split(';'))].filter(Boolean),
            };
            this.updateIntegration(projectToUpdate);
        },
    },
};
</script>

<style></style>
