var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.project.severities,"items-per-page":10,"sort-by":"name"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"transparent"}},[_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"800px","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"rounded":"","color":"primary","dark":""}},on),[_vm._v(" "+_vm._s(_vm.$t('buttons.createSeverity'))+" ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline blue--text text--darken-1"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-text-field',{attrs:{"counter":20,"label":_vm.$t('projects.severities.value'),"required":"","color":"red"},model:{value:(_vm.editSeverity.value),callback:function ($$v) {_vm.$set(_vm.editSeverity, "value", $$v)},expression:"editSeverity.value"}}),_c('v-color-picker',{staticClass:"mb-4",attrs:{"hide-canvas":"","hide-mode-switch":"","show-swatches":"","hide-inputs":"","flat":"","width":"100%","height":"200"},model:{value:(_vm.editSeverity.color),callback:function ($$v) {_vm.$set(_vm.editSeverity, "color", $$v)},expression:"editSeverity.color"}}),_c('v-switch',{attrs:{"label":_vm.$t('projects.severities.default')},model:{value:(_vm.editSeverity.default),callback:function ($$v) {_vm.$set(_vm.editSeverity, "default", $$v)},expression:"editSeverity.default"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":_vm.cancelEdit}},[_vm._v(" "+_vm._s(_vm.$t('buttons.cancel'))+" ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('buttons.save'))+" ")])],1)],1)],1)],1)]},proxy:true},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.default ? 'subtitle-2' : ''},[_vm._v(_vm._s(_vm.$t('lists.severities.' + item.value)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"item.color",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"color":item.color,"size":"36"}})]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }