<template>
    <v-card>
        <v-dialog v-model="dialogDelete" max-width="800px" persistent>
            <v-card>
                <v-card-title>
                    <span class="headline red--text text--darken-1" v-text="$t('title.deleteModule')"></span>
                </v-card-title>
                <v-card-text>
                    <span class="subtitle-2 text--darken-1" v-text="$t('title.deleteModuleReplace')"></span>
                </v-card-text>

                <v-card-text>
                    <module-selector v-model="deletingModule.replaceName" :excludes="[deletingModule.name]" />
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey darken-1" text @click="cancelDelete">
                        {{ $t('buttons.cancel') }}
                    </v-btn>
                    <v-btn color="red darken-1" text @click="removeModule">
                        {{ $t('buttons.delete') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-card-text>
            <v-data-table :headers="headers" :items="modules" :items-per-page="20" sort-by="name">
                <template v-slot:top>
                    <v-toolbar flat color="transparent">
                        <v-spacer></v-spacer>
                        <v-dialog v-model="dialog" max-width="800px" persistent>
                            <template v-slot:activator="{ on }">
                                <v-btn rounded color="primary" dark v-on="on">
                                    {{ $t('buttons.createModule') }}
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-title>
                                    <span class="headline blue--text text--darken-1">{{ formTitle }}</span>
                                </v-card-title>

                                <v-card-text>
                                    <v-text-field
                                        v-model="editModule"
                                        :counter="20"
                                        :label="$t('projects.modules.name')"
                                        required
                                        color="red"
                                    ></v-text-field>
                                </v-card-text>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="red darken-1" text @click="cancelEdit">
                                        {{ $t('buttons.cancel') }}
                                    </v-btn>
                                    <v-btn color="blue darken-1" text @click="save">
                                        {{ $t('buttons.save') }}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>

                <template v-slot:[`item.name`]="{ item }">
                    {{ item.name }}
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
                    <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moduleSelector from '@/components/selectors/simple/module';

export default {
    name: 'project-setting-modules',
    data: () => ({
        dialog: false,
        editModule: undefined,
        dialogDelete: false,
        deletingModule: {},
    }),
    components: { moduleSelector },
    computed: {
        ...mapGetters(['project']),
        modules() {
            return this.project.modules.map((m) => {
                return {
                    name: m,
                };
            });
        },
        headers() {
            return [
                {
                    text: this.$t('projects.modules.name'),
                    sortable: true,
                    value: 'name',
                },
                {
                    text: this.$t('projects.modules.actions'),
                    sortable: false,
                    value: 'actions',
                    width: 50,
                },
            ];
        },
        formTitle() {
            return this.editModule ? this.$t('title.editModule') : this.$t('title.newModule');
        },
    },
    methods: {
        ...mapActions(['updateModule', 'deleteModule']),
        cancelEdit() {
            this.dialog = false;
            this.editModule = undefined;
        },
        cancelDelete() {
            this.dialogDelete = false;
            this.deletingModule = {};
        },
        editItem(item) {
            this.dialog = true;
            this.editModule = item.name;
        },
        deleteItem(item) {
            this.dialogDelete = true;
            this.deletingModule = {
                name: item.name,
                replaceName: '',
            };
        },
        removeModule() {
            const moduleToUpdate = {
                id: this.project.id,
                name: this.deletingModule.name,
                replaceName: this.deletingModule.replaceName,
            };

            this.deleteModule(moduleToUpdate);
            this.cancelDelete();
        },
        save() {
            const moduleToUpdate = {
                id: this.project.id,
                name: this.editModule,
            };

            this.updateModule(moduleToUpdate);
            this.cancelEdit();
        },
    },
};
</script>

<style></style>
