<template>
    <v-card>
        <v-card-text>
            <v-data-table :headers="headers" :items="project.priorities" :items-per-page="10" sort-by="name">
                <template v-slot:top>
                    <v-toolbar flat color="transparent">
                        <v-spacer></v-spacer>
                        <v-dialog v-model="dialog" max-width="800px" persistent>
                            <template v-slot:activator="{ on }">
                                <v-btn rounded color="primary" dark v-on="on">
                                    {{ $t('buttons.createPriority') }}
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-title>
                                    <span class="headline blue--text text--darken-1">{{ formTitle }}</span>
                                </v-card-title>

                                <v-card-text>
                                    <v-text-field
                                        v-model="editPriority.value"
                                        :counter="20"
                                        :label="$t('projects.priorities.value')"
                                        required
                                        color="red"
                                    ></v-text-field>
                                    <v-color-picker
                                        v-model="editPriority.color"
                                        hide-canvas
                                        hide-mode-switch
                                        show-swatches
                                        hide-inputs
                                        flat
                                        width="100%"
                                        height="200"
                                        class="mb-4"
                                    ></v-color-picker>
                                    <v-switch
                                        v-model="editPriority.default"
                                        :label="$t('projects.priorities.default')"
                                    ></v-switch>
                                </v-card-text>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="red darken-1" text @click="cancelEdit">
                                        {{ $t('buttons.cancel') }}
                                    </v-btn>
                                    <v-btn color="blue darken-1" text @click="save">
                                        {{ $t('buttons.save') }}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>

                <template v-slot:[`item.value`]="{ item }">
                    <span :class="item.default ? 'subtitle-2' : ''">{{ $t('lists.priorities.' + item.value) }}</span>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
                    <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
                </template>
                <template v-slot:[`item.color`]="{ item }">
                    <v-avatar :color="item.color" size="36"> &nbsp; </v-avatar>
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'project-setting-priorities',
    data: () => ({
        dialog: false,
        editPriority: {
            value: undefined,
            color: '#000000',
            default: false,
        },
    }),
    created() {},
    watch: {},
    computed: {
        ...mapGetters(['project']),
        headers() {
            return [
                {
                    text: '',
                    sortable: false,
                    value: 'color',
                    width: 36,
                },
                {
                    text: this.$t('projects.priorities.value'),
                    sortable: true,
                    value: 'value',
                    sort: (a, b) => {
                        return this.$t('lists.priorities.' + a).localeCompare(this.$t('lists.priorities.' + b));
                    },
                },
                {
                    text: this.$t('projects.priorities.actions'),
                    sortable: false,
                    value: 'actions',
                    width: 50,
                },
            ];
        },
        formTitle() {
            return this.editPriority.value ? this.$t('title.editPriority') : this.$t('title.newPriority');
        },
    },
    methods: {
        ...mapActions(['updatePriority', 'deletePriority']),
        cancelEdit() {
            this.dialog = false;
            this.editPriority = {
                value: undefined,
                color: '#000000',
                default: false,
            };
        },
        editItem(item) {
            this.dialog = true;
            this.editPriority.value = item.value;
            this.editPriority.color = item.color;
            this.editPriority.default = item.default;
        },
        deleteItem(item) {
            const priorityToUpdate = {
                id: this.project.id,
                value: item.value,
            };

            this.deletePriority(priorityToUpdate);
        },
        save() {
            const priorityToUpdate = {
                id: this.project.id,
                value: this.editPriority.value,
                color: this.editPriority.color,
                default: this.editPriority.default,
            };

            this.updatePriority(priorityToUpdate);
            this.cancelEdit();
        },
    },
};
</script>

<style></style>
