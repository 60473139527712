<template>
    <app-drawer
        :menus="menus"
        :allow-mini="false"
        :clipped="false"
        :dark="false"
        :floating="true"
        color="transparent"
        :app="false"
    />
</template>

<script>
import { mapGetters } from 'vuex';
import appDrawer from './drawer';

export default {
    name: 'drawer-project-settings',
    data: () => ({
        mini: true,
    }),
    components: {
        appDrawer,
    },
    computed: {
        ...mapGetters(['profile']),
        allMenus() {
            return [
                {
                    label: 'projects.settings.definition',
                    icon: 'mdi-text-box',
                    name: 'definition',
                    group: 'projects.settings.general',
                    position: 'top',
                },
                {
                    label: 'projects.settings.versions',
                    icon: 'mdi-tag-multiple',
                    name: 'versions',
                    group: 'projects.settings.attributes',
                    position: 'top',
                },
                {
                    label: 'projects.settings.modules',
                    icon: 'mdi-folder-star-multiple',
                    name: 'modules',
                    group: 'projects.settings.attributes',
                    position: 'top',
                },
                {
                    label: 'projects.settings.categories',
                    icon: 'mdi-folder-multiple',
                    name: 'categories',
                    group: 'projects.settings.attributes',
                    position: 'top',
                },
                {
                    label: 'projects.settings.members',
                    icon: 'mdi-account-group',
                    name: 'members',
                    group: 'projects.settings.attributes',
                    position: 'top',
                },
                {
                    label: 'projects.settings.views',
                    icon: 'mdi-format-list-bulleted',
                    name: 'views',
                    group: 'projects.settings.attributes',
                    position: 'top',
                },
                {
                    label: 'projects.settings.fields',
                    icon: 'mdi-list-status',
                    name: 'fields',
                    group: 'projects.settings.attributes',
                    position: 'top',
                },
                {
                    label: 'projects.settings.gitlab',
                    icon: 'mdi-gitlab',
                    name: 'gitlab',
                    group: 'projects.settings.integrations',
                    position: 'top',
                },
                /*{
                    label: 'projects.settings.team',
                    icon: 'mdi-microsoft-teams',
                    name: 'team',
                    group: 'projects.settings.integrations',
                    position: 'top',
                },*/
                {
                    label: 'projects.settings.jenkins',
                    icon: 'mdi-webpack',
                    name: 'jenkins',
                    group: 'projects.settings.integrations',
                    position: 'top',
                },
            ];
        },
        menus() {
            return this.allMenus;
        },
    },
};
</script>
