<template>
    <v-card>
        <v-card-text>
            <v-data-table :headers="headers" :items="project.trackers" :items-per-page="10" sort-by="name">
                <template v-slot:top>
                    <v-toolbar flat color="transparent">
                        <v-spacer></v-spacer>
                        <v-dialog v-model="dialog" max-width="800px" persistent>
                            <template v-slot:activator="{ on }">
                                <v-btn rounded color="primary" dark v-on="on">
                                    {{ $t('buttons.createTracker') }}
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-title>
                                    <span class="headline blue--text text--darken-1">{{ formTitle }}</span>
                                </v-card-title>

                                <v-card-text>
                                    <v-text-field
                                        v-model="editTracker.name"
                                        :counter="20"
                                        :label="$t('projects.trackers.name')"
                                        required
                                        color="red"
                                    ></v-text-field>
                                    <v-color-picker
                                        v-model="editTracker.color"
                                        hide-canvas
                                        hide-mode-switch
                                        show-swatches
                                        hide-inputs
                                        flat
                                        width="100%"
                                        height="200"
                                        class="mb-4"
                                    ></v-color-picker>
                                    <v-switch
                                        v-model="editTracker.default"
                                        :label="$t('projects.trackers.default')"
                                    ></v-switch>
                                    <roles-selector
                                        :label="$t('projects.trackers.notifications')"
                                        v-model="editTracker.notifications"
                                    />
                                    <roles-selector
                                        :label="$t('projects.trackers.roles')"
                                        v-model="editTracker.roles"
                                    />

                                    <status-selector
                                        :label="$t('projects.trackers.hiddenStatus')"
                                        v-model="editTracker.hiddenStatus"
                                    />

                                    <issue-attr-selector
                                        :label="$t('projects.trackers.hiddenAttributes')"
                                        v-model="editTracker.mandatoryAttributes"
                                    />
                                </v-card-text>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="red darken-1" text @click="cancelEdit">
                                        {{ $t('buttons.cancel') }}
                                    </v-btn>
                                    <v-btn color="blue darken-1" text @click="save">
                                        {{ $t('buttons.save') }}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>

                <template v-slot:[`item.name`]="{ item }">
                    <span :class="item.default ? 'subtitle-2' : ''">{{ $t('lists.trackers.' + item.name) }}</span>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
                    <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
                </template>
                <template v-slot:[`item.color`]="{ item }">
                    <v-avatar :color="item.color" size="36"> &nbsp; </v-avatar>
                </template>
                <template v-slot:[`item.notifications`]="{ item }">
                    <labelRoles :value="item.notifications" color="amber darken-2" />
                </template>
                <template v-slot:[`item.roles`]="{ item }">
                    <labelRoles :value="item.roles" color="teal darken-2" />
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import labelRoles from '@/components/labels/roles';
import rolesSelector from '@/components/selectors/multiple/roles';
import statusSelector from '@/components/selectors/multiple/status';
import issueAttrSelector from '@/components/selectors/multiple/issueAttributes';

export default {
    name: 'project-setting-trackers',
    data: () => ({
        dialog: false,
        editTracker: {
            name: undefined,
            color: '#000000',
            default: false,
            notifications: [],
            roles: [],
            hiddenAttributes: [],
            hiddenStatus: [],
        },
    }),
    created() {},
    components: {
        labelRoles,
        rolesSelector,
        statusSelector,
        issueAttrSelector,
    },
    watch: {},
    computed: {
        ...mapGetters(['project']),
        headers() {
            return [
                {
                    text: '',
                    sortable: false,
                    value: 'color',
                    width: 36,
                },
                {
                    text: this.$t('projects.trackers.name'),
                    sortable: true,
                    value: 'name',
                    sort: (a, b) => {
                        return this.$t('lists.trackers.' + a).localeCompare(this.$t('lists.trackers.' + b));
                    },
                },
                {
                    text: this.$t('projects.trackers.notifications'),
                    sortable: false,
                    value: 'notifications',
                },
                {
                    text: this.$t('projects.trackers.roles'),
                    sortable: false,
                    value: 'roles',
                },
                {
                    text: this.$t('projects.trackers.actions'),
                    sortable: false,
                    value: 'actions',
                    width: 50,
                },
            ];
        },
        formTitle() {
            return this.editTracker.name ? this.$t('title.editTracker') : this.$t('title.newTracker');
        },
    },
    methods: {
        ...mapActions(['updateTracker', 'deleteTracker']),
        cancelEdit() {
            this.dialog = false;
            this.editTracker = {
                name: undefined,
                color: '#000000',
                default: false,
                notifications: [],
                roles: [],
                hiddenAttributes: [],
                hiddenStatus: [],
            };
        },
        editItem(item) {
            this.dialog = true;
            this.editTracker = Object.assign({}, item);
        },
        deleteItem(item) {
            const trackerToUpdate = {
                id: this.project.id,
                name: item.name,
            };

            this.deleteTracker(trackerToUpdate);
        },
        save() {
            const trackerToUpdate = {
                id: this.project.id,
                name: this.editTracker.name,
                color: this.editTracker.color,
                default: this.editTracker.default,
                notifications: this.editTracker.notifications,
                roles: this.editTracker.roles,
                hiddenAttributes: this.editTracker.hiddenAttributes,
                hiddenStatus: this.editTracker.hiddenStatus,
            };

            this.updateTracker(trackerToUpdate);
            this.cancelEdit();
        },
    },
};
</script>

<style></style>
