<template>
    <v-card>
        <v-card-text>
            <v-text-field
                v-model="label"
                :counter="20"
                :label="$t('projects.attributes.label')"
                required
                outlined
                color="red"
            ></v-text-field>
            <v-text-field
                v-model="name"
                :counter="20"
                :label="$t('projects.attributes.name')"
                required
                outlined
            ></v-text-field>
            <v-text-field
                v-model="group"
                :counter="20"
                :label="$t('projects.attributes.group')"
                required
                outlined
            ></v-text-field>
            <v-textarea
                v-model="description"
                :label="$t('projects.attributes.description')"
                required
                outlined=""
            ></v-textarea>
            <v-color-picker
                v-model="color"
                hide-canvas
                hide-mode-switch
                show-swatches
                hide-inputs
                flat
                width="100%"
                height="200"
                class="mb-4"
            ></v-color-picker>
            <v-text-field
                v-model="icon"
                :label="$t('projects.attributes.icon')"
                outlined
                :append-outer-icon="'mdi-' + icon"
            ></v-text-field>

            <v-btn color="success" class="mr-4" @click="submitForm">
                {{ $t('projects.actions.update') }}
            </v-btn>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'project-setting-general',
    data: () => ({
        label: undefined,
        name: undefined,
        group: undefined,
        color: undefined,
        icon: undefined,
        description: undefined,
    }),
    created() {
        this.updateForm(this.project);
    },
    watch: {
        project: function (value) {
            this.updateForm(value);
        },
    },
    computed: {
        ...mapGetters(['project']),
    },
    methods: {
        ...mapActions(['updateProject']),
        updateForm(p) {
            if (p) {
                this.label = p.label;
                this.name = p.name;
                this.group = p.group;
                this.color = p.color;
                this.icon = p.icon;
                this.description = p.description;
            }
        },
        submitForm() {
            const projectToUpdate = {
                id: this.project.id,
                label: this.label,
                name: this.name,
                group: this.group,
                color: this.color,
                icon: this.icon,
                description: this.description,
            };
            this.updateProject(projectToUpdate);
        },
    },
};
</script>

<style></style>
