<template>
    <v-card>
        <v-dialog v-model="dialogDelete" max-width="800px" persistent>
            <v-card>
                <v-card-title>
                    <span class="headline red--text text--darken-1" v-text="$t('title.deleteVersion')"></span>
                </v-card-title>
                <v-card-text>
                    <span class="subtitle-2 text--darken-1" v-text="$t('title.deleteVersionReplace')"></span>
                </v-card-text>

                <v-card-text>
                    <version-selector v-model="deletingVersion.replaceName" :excludes="[deletingVersion.name]" />
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey darken-1" text @click="cancelDelete">
                        {{ $t('buttons.cancel') }}
                    </v-btn>
                    <v-btn color="red darken-1" text @click="removeVersion">
                        {{ $t('buttons.delete') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-card-text>
            <v-data-table
                :headers="headers"
                :items="project.versions"
                :items-per-page="100"
                :sort-by="'name'"
                :sort-desc="true"
                multi-sort
                :footer-props="{
                    'items-per-page-options': [10, 20, 50, 100, 200],
                    'show-current-page': true,
                    'show-first-last-page': true,
                }"
            >
                <template v-slot:top>
                    <v-toolbar flat color="transparent">
                        <v-spacer></v-spacer>
                        <v-dialog v-model="dialog" max-width="800px" persistent>
                            <template v-slot:activator="{ on }">
                                <v-btn rounded color="primary" dark v-on="on">
                                    {{ $t('buttons.createVersion') }}
                                </v-btn>
                            </template>
                            <v-card>
                                <ValidationObserver ref="observer" v-slot="{ invalid }">
                                    <v-card-title>
                                        <span class="headline blue--text text--darken-1">{{ formTitle }}</span>
                                    </v-card-title>

                                    <v-card-text>
                                        <v-row>
                                            <v-col cols="12" md="6">
                                                <ValidationProvider
                                                    :name="$t('projects.versions.name')"
                                                    :rules="'required'"
                                                    v-slot="{ errors }"
                                                >
                                                    <v-text-field
                                                        v-model="editVersion.name"
                                                        :counter="20"
                                                        :label="'* ' + $t('projects.versions.name')"
                                                        :error-messages="errors[0]"
                                                        color="red"
                                                    ></v-text-field>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" md="6">
                                                <v-text-field
                                                    v-model="editVersion.label"
                                                    :counter="20"
                                                    :label="$t('projects.versions.label')"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                    <v-card-text>
                                        <v-text-field
                                            v-model="editVersion.documentation"
                                            :label="$t('projects.versions.documentation')"
                                        ></v-text-field>
                                    </v-card-text>
                                    <v-card-text>
                                        <date-selector
                                            v-model="editVersion.date"
                                            :label="$t('projects.versions.date')"
                                        />
                                    </v-card-text>
                                    <v-card-text>
                                        <v-row justify="space-around">
                                            <v-col cols="12">
                                                <header>{{ $t('projects.versions.status') }}</header>
                                            </v-col>
                                            <v-radio-group v-model="editVersion.status" :column="false">
                                                <v-radio
                                                    v-for="status in versionStatus"
                                                    :key="status"
                                                    class="mx-2"
                                                    :label="$t('lists.versionStatus.' + status)"
                                                    :value="status"
                                                ></v-radio>
                                            </v-radio-group>
                                        </v-row>
                                    </v-card-text>
                                    <v-card-text v-if="editVersion.status !== 'major'">
                                        <version-selector
                                            :label="$t('projects.versions.parent')"
                                            v-model="editVersion.parent"
                                            version-status="major"
                                        />
                                    </v-card-text>
                                    <v-card-text>
                                        <v-switch
                                            v-model="editVersion.development"
                                            :label="$t('projects.versions.development')"
                                        ></v-switch>
                                    </v-card-text>

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="red darken-1" text @click="cancelEdit">
                                            {{ $t('buttons.cancel') }}
                                        </v-btn>
                                        <v-btn color="blue darken-1" text @click="save" :disabled="invalid">
                                            {{ $t('buttons.save') }}
                                        </v-btn>
                                    </v-card-actions>
                                </ValidationObserver>
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>

                <template v-slot:[`item.name`]="{ item }">
                    {{ item.name }}
                    <template v-if="item.label"> ({{ item.label }}) </template>
                </template>

                <template v-slot:[`item.development`]="{ item }">
                    <v-avatar size="24" :color="item.development ? 'red lighten-1' : 'light-green lighten-1'">
                    </v-avatar>
                </template>

                <template v-slot:[`item.status`]="{ item }">
                    <v-avatar size="24">
                        <v-icon v-if="item.status === 'major'">mdi-check-decagram</v-icon>
                        <v-icon v-if="item.status === 'minor'">mdi-alert-decagram</v-icon>
                        <v-icon v-if="item.status === 'fix'">mdi-decagram</v-icon>
                    </v-avatar>
                </template>

                <template v-slot:[`item.date`]="{ item }">
                    {{ formatDate(item.date) }}
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
                    <v-icon v-if="item.development" small @click="deleteItem(item)"> mdi-delete </v-icon>
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import dateSelector from '@/components/selectors/simple/date';
import versionSelector from '@/components/selectors/simple/version';
import { formatDateMixin } from '@/mixins/dates';

export default {
    name: 'project-setting-version',
    data: () => ({
        dialog: false,
        editVersion: {
            id: undefined,
            label: undefined,
            name: undefined,
            parent: undefined,
            documentation: undefined,
            status: 'major',
            date: new Date(),
            development: true,
        },
        dialogDelete: false,
        deletingVersion: {},
    }),
    created() {},
    mixins: [formatDateMixin],
    components: {
        dateSelector,
        versionSelector,
    },
    watch: {},
    computed: {
        ...mapGetters(['project', 'versionStatus']),
        headers() {
            return [
                {
                    text: this.$t('projects.versions.development'),
                    sortable: true,
                    value: 'development',
                    width: 24,
                },
                {
                    text: '',
                    sortable: false,
                    value: 'status',
                    width: 24,
                },
                {
                    text: this.$t('projects.versions.name'),
                    sortable: true,
                    value: 'name',
                },
                {
                    text: this.$t('projects.versions.date'),
                    sortable: true,
                    value: 'date',
                },
                {
                    text: this.$t('projects.versions.actions'),
                    sortable: false,
                    value: 'actions',
                    width: 50,
                },
            ];
        },
        formTitle() {
            return this.editVersion.id ? this.$t('title.editVersion') : this.$t('title.newVersion');
        },
    },
    methods: {
        ...mapActions(['addVersion', 'updateVersion', 'deleteVersion']),
        cancelEdit() {
            this.dialog = false;
            this.editVersion = {
                id: undefined,
                label: undefined,
                name: undefined,
                parent: undefined,
                documentation: undefined,
                status: 'major',
                date: new Date(),
            };
        },
        cancelDelete() {
            this.dialogDelete = false;
            this.deletingVersion = {};
        },
        editItem(item) {
            this.dialog = true;
            this.editVersion.id = item.id;
            this.editVersion.label = item.label;
            this.editVersion.name = item.name;
            this.editVersion.parent = item.parent;
            this.editVersion.date = new Date(item.date);
            this.editVersion.status = item.status;
            this.editVersion.documentation = item.documentation;
            this.editVersion.development = item.development;
        },
        deleteItem(item) {
            this.dialogDelete = true;
            this.deletingVersion = {
                id: this.project.id,
                versionId: item.id,
                name: item.name,
            };
        },
        removeVersion() {
            const versionToUpdate = {
                id: this.project.id,
                versionId: this.deletingVersion.versionId,
                replaceName: this.deletingVersion.replaceName,
            };

            this.deleteVersion(versionToUpdate);
            this.cancelDelete();
        },
        save() {
            const versionToUpdate = {
                id: this.project.id,
                versionId: this.editVersion.id,
                label: this.editVersion.label,
                name: this.editVersion.name,
                parent: this.editVersion.parent,
                date: this.editVersion.date,
                status: this.editVersion.status,
                documentation: this.editVersion.documentation,
                development: this.editVersion.development,
            };

            if (this.editVersion.id) {
                this.updateVersion(versionToUpdate);
            } else {
                this.addVersion(versionToUpdate);
            }

            this.cancelEdit();
        },
    },
};
</script>

<style></style>
