var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-dialog',{attrs:{"max-width":"800px","persistent":""},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline red--text text--darken-1",domProps:{"textContent":_vm._s(_vm.$t('title.deleteCategory'))}})]),_c('v-card-text',[_c('span',{staticClass:"subtitle-2 text--darken-1",domProps:{"textContent":_vm._s(_vm.$t('title.deleteCategoryReplace'))}})]),_c('v-card-text',[_c('category-selector',{attrs:{"excludes":[_vm.deletingCategory.name]},model:{value:(_vm.deletingCategory.replaceName),callback:function ($$v) {_vm.$set(_vm.deletingCategory, "replaceName", $$v)},expression:"deletingCategory.replaceName"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey darken-1","text":""},on:{"click":_vm.cancelDelete}},[_vm._v(" "+_vm._s(_vm.$t('buttons.cancel'))+" ")]),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":_vm.removeCategory}},[_vm._v(" "+_vm._s(_vm.$t('buttons.delete'))+" ")])],1)],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.project.categories,"items-per-page":20,"sort-by":"name"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"transparent"}},[_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"800px","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"rounded":"","color":"primary","dark":""}},on),[_vm._v(" "+_vm._s(_vm.$t('buttons.createCategory'))+" ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline blue--text text--darken-1"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-text-field',{attrs:{"counter":20,"label":_vm.$t('projects.categories.name'),"required":"","color":"red"},model:{value:(_vm.editCategory.name),callback:function ($$v) {_vm.$set(_vm.editCategory, "name", $$v)},expression:"editCategory.name"}}),_c('selector-user',{attrs:{"label":_vm.$t('projects.categories.user')},model:{value:(_vm.editCategory.user),callback:function ($$v) {_vm.$set(_vm.editCategory, "user", $$v)},expression:"editCategory.user"}}),_c('selector-modules',{attrs:{"label":_vm.$t('projects.categories.modules')},model:{value:(_vm.editCategory.modules),callback:function ($$v) {_vm.$set(_vm.editCategory, "modules", $$v)},expression:"editCategory.modules"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":_vm.cancelEdit}},[_vm._v(" "+_vm._s(_vm.$t('buttons.cancel'))+" ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('buttons.save'))+" ")])],1)],1)],1)],1)]},proxy:true},{key:"item.modules",fn:function(ref){
var item = ref.item;
return [_c('label-modules',{attrs:{"value":item.modules}})]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_c('label-user',{attrs:{"value":item.user,"avatar":false}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }