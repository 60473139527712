<template>
    <v-select v-model="localModules" :items="selector" chips :label="label" multiple></v-select>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import OrderBy from 'lodash/orderBy';
export default {
    name: 'selector-modules',
    data: () => ({
        localModules: [],
    }),
    created() {},
    mounted() {
        this.localModules = this.value;
    },
    props: {
        value: {},
        label: {},
    },
    watch: {
        localModules: function (value) {
            this.$emit('input', value);
        },
        value: function (value) {
            this.localModules = value;
        },
    },
    computed: {
        ...mapGetters(['project']),
        selector() {
            return OrderBy(
                this.project.modules.map((m) => {
                    return {
                        value: m,
                        text: m,
                    };
                }),
                ['value'],
                ['asc'],
            );
        },
    },
    methods: {},
};
</script>
