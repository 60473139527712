<template>
    <v-card>
        <v-card-text>
            <v-data-table
                :headers="headers"
                :items="project.status"
                :items-per-page="10"
                :sort-by="['open', 'name']"
                :sort-desc="[true, false]"
            >
                <template v-slot:top>
                    <v-toolbar flat color="transparent">
                        <v-spacer></v-spacer>
                        <v-dialog v-model="dialog" max-width="800px" persistent>
                            <template v-slot:activator="{ on }">
                                <v-btn rounded color="primary" dark v-on="on">
                                    {{ $t('buttons.createStatus') }}
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-title>
                                    <span class="headline blue--text text--darken-1">{{ formTitle }}</span>
                                </v-card-title>

                                <v-card-text>
                                    <v-text-field
                                        v-model="editStatus.name"
                                        :counter="20"
                                        :label="$t('projects.status.name')"
                                        required
                                        color="red"
                                    ></v-text-field>
                                    <v-color-picker
                                        v-model="editStatus.color"
                                        hide-canvas
                                        hide-mode-switch
                                        show-swatches
                                        hide-inputs
                                        flat
                                        width="100%"
                                        height="200"
                                        class="mb-4"
                                    ></v-color-picker>
                                    <v-switch
                                        v-model="editStatus.default"
                                        :label="$t('projects.status.default')"
                                    ></v-switch>
                                    <v-switch v-model="editStatus.open" :label="$t('projects.status.open')"></v-switch>

                                    <status-selector :label="$t('projects.status.next')" v-model="editStatus.next" />

                                    <issue-attr-selector
                                        :label="$t('projects.status.mandatoryAttributes')"
                                        v-model="editStatus.mandatoryAttributes"
                                    />
                                </v-card-text>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="red darken-1" text @click="cancelEdit">
                                        {{ $t('buttons.cancel') }}
                                    </v-btn>
                                    <v-btn color="blue darken-1" text @click="save">
                                        {{ $t('buttons.save') }}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>

                <template v-slot:[`item.name`]="{ item }">
                    <span :class="item.default ? 'subtitle-2' : ''">{{ $t('lists.status.' + item.name) }}</span>
                </template>
                <template v-slot:[`item.next`]="{ item }">
                    <labelStatus :value="item.next" color="amber darken-2" />
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
                    <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
                </template>
                <template v-slot:[`item.color`]="{ item }">
                    <v-avatar :color="item.color" size="36">
                        <v-icon v-if="item.open">mdi-refresh</v-icon>
                        <v-icon v-else>mdi-check</v-icon>
                    </v-avatar>
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import labelStatus from '@/components/labels/status';
import statusSelector from '@/components/selectors/multiple/status';
import issueAttrSelector from '@/components/selectors/multiple/issueAttributes';

export default {
    name: 'project-setting-status',
    data: () => ({
        dialog: false,
        editStatus: {
            name: undefined,
            color: '#000000',
            default: false,
            open: true,
            next: [],
            mandatoryAttributes: [],
        },
    }),
    created() {},
    components: {
        labelStatus,
        statusSelector,
        issueAttrSelector,
    },
    watch: {},
    computed: {
        ...mapGetters(['project']),
        headers() {
            return [
                {
                    text: '',
                    sortable: false,
                    value: 'color',
                    width: 36,
                },
                {
                    text: this.$t('projects.status.name'),
                    sortable: true,
                    value: 'name',
                    sort: (a, b) => {
                        return this.$t('lists.status.' + a).localeCompare(this.$t('lists.status.' + b));
                    },
                },
                {
                    text: this.$t('projects.status.next'),
                    sortable: false,
                    value: 'next',
                    sort: (a, b) => {
                        return this.$t('lists.status.' + a).localeCompare(this.$t('lists.status.' + b));
                    },
                },
                {
                    text: this.$t('projects.status.actions'),
                    sortable: false,
                    value: 'actions',
                    width: 50,
                },
            ];
        },
        formTitle() {
            return this.editStatus.name ? this.$t('title.editStatus') : this.$t('title.newStatus');
        },
    },
    methods: {
        ...mapActions(['updateStatus', 'deleteStatus']),
        cancelEdit() {
            this.dialog = false;
            this.editStatus = {
                name: undefined,
                color: '#000000',
                default: false,
                open: true,
                next: [],
                mandatoryAttributes: [],
            };
        },
        editItem(item) {
            this.dialog = true;
            this.editStatus.name = item.name;
            this.editStatus.color = item.color;
            this.editStatus.default = item.default;
            this.editStatus.open = item.open;
            this.editStatus.next = item.next;
            this.editStatus.mandatoryAttributes = item.mandatoryAttributes;
        },
        deleteItem(item) {
            const statusToUpdate = {
                id: this.project.id,
                name: item.name,
            };

            this.deleteStatus(statusToUpdate);
        },
        save() {
            const statusToUpdate = {
                id: this.project.id,
                name: this.editStatus.name,
                color: this.editStatus.color,
                default: this.editStatus.default,
                open: this.editStatus.open,
                next: this.editStatus.next,
                mandatoryAttributes: this.editStatus.mandatoryAttributes,
            };

            this.updateStatus(statusToUpdate);
            this.cancelEdit();
        },
    },
};
</script>

<style></style>
